import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = { speed: { type: Number, default: 0.4 } }

  connect() {
    console.log('test')
    this.scrollHandler = this.handleScroll.bind(this)
    window.addEventListener('scroll', this.scrollHandler)    
    this.element.style.backgroundAttachment = 'scroll'
  }

  disconnect() {
    window.removeEventListener('scroll', this.scrollHandler)
  }

  handleScroll() {
    const scrolled = window.pageYOffset
    const translateY = scrolled * this.speedValue
    this.element.style.backgroundPositionY = `${translateY}px`
  }
}