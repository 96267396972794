// app/javascript/controllers/bundle_variant_controller.js
import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  connect() {
    this.element.querySelectorAll('input[type="checkbox"]').forEach(checkbox => {
      checkbox.addEventListener('change', this.handleChange.bind(this))
    })
  }

  handleChange(event) {
    const currentCheckbox = event.target
    const checkboxes = currentCheckbox.closest('div').querySelectorAll('input[type="checkbox"]')
    
    checkboxes.forEach(cb => {
      if (cb !== currentCheckbox) cb.checked = false
    })
    
    currentCheckbox.form.requestSubmit()
  }
}