import { Controller } from "@hotwired/stimulus"
import "particles.js"

export default class extends Controller {
  connect() {
    this.initializeParticles()
  }

  initializeParticles() {
    particlesJS(this.element.id, {
      particles: {
        number: {
          value: 35,
          density: {
            enable: true,
            value_area: 400
          }
        },
        color: {
          value: "#fff5d1"
        },
        shape: {
          type: "star",
          stroke: {
            width: 0
          },
          polygon: {
            nb_sides: 5
          }
        },
        opacity: {
          value: 0.8,
          random: true,
          animation: {
            enable: true,
            speed: 0.3,
            opacity_min: 0.4,
            sync: false
          }
        },
        size: {
          value: 4,
          random: true,
          animation: {
            enable: true,
            speed: 1,
            size_min: 1,
            sync: false
          }
        },
        line_linked: {
          enable: false 
        },
        move: {
          enable: true,
          speed: 0.8,
          direction: "top",
          random: false,
          straight: false,
          out_mode: "out",
          bounce: false,
          attract: {
            enable: false
          }
        },
      },
      interactivity: {
        detect_on: "canvas",
        events: {
          onhover: {
            enable: false
          },
          onclick: {
            enable: false
          },
          resize: true
        }
      },
      retina_detect: true
    });
  }

  disconnect() {
    if (window.pJSDom && window.pJSDom.length) {
      window.pJSDom[0].pJS.fn.vendors.destroypJS();
      window.pJSDom = [];
    }
  }
}